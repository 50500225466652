body {
    font-family: Arial, sans-serif;
}

nav {
    background-color: #333;
    color: white;
    padding: 10px;
}

nav h1 {
    display: inline;
}

nav ul {
    list-style: none;
    display: inline;
    padding: 0;
}

nav ul li {
    display: inline;
    margin-right: 10px;
}

nav ul li a {
    color: white;
    text-decoration: none;
}

h1, h2 {
    margin-top: 20px;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

table, th, td {
    border: 1px solid black;
}

th, td {
    padding: 10px;
    text-align: left;
}
